import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import React from 'react';
import {FormattedMessage, defineMessage, MessageDescriptor} from 'react-intl';
import styles from './index.module.scss';

const copyrightRuMessage = defineMessage({
  defaultMessage: '"Internet Projects" LLC (Armenia)',
  description: 'Footer. Signature for copyright (for the Russian version of the site)',
});

const copyrightEnMessage = defineMessage({
  defaultMessage: 'SIA Joom (Latvija)',
  description: 'Footer. Signature for copyright',
});

const copyrightMessageByLanguage: Record<Language, MessageDescriptor> = {
  [Language.PT_BR]: copyrightEnMessage,
  [Language.EN]: copyrightRuMessage,
  [Language.RU]: copyrightRuMessage,
};

export function FooterBottom(): React.ReactElement {
  const language = useLanguage();
  const showYear = language !== Language.RU;
  const message = copyrightMessageByLanguage[language];

  return (
    <div>
      <span className={styles.copyright}>
        {showYear && <span className={styles.year}>&copy; {new Date().getFullYear()}</span>}
        <span>
          <FormattedMessage {...message} />
        </span>
      </span>
    </div>
  );
}
