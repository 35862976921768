import cn from 'classnames';
import {Link} from 'components/Link';
import React from 'react';
import styles from './index.module.scss';

export function InstagramLogoLink(): React.ReactElement {
  return (
    <Link href='https://www.instagram.com/joomprobrasil/'>
      <div className={cn(styles.logo, styles.instagram)} />
    </Link>
  );
}
