import cn from 'classnames';
import {Office} from 'components/Footer/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {FLAG_SRC, MOBILE_OFFICES_NUMBER} from '../constants';
import styles from './index.module.scss';

/* eslint-disable max-len */
export const officeMessages = defineMessages<Office>({
  br: {
    defaultMessage: 'JoomPro Brazil LTDA Brasil, Florianópolis SC, Centro, R Bocaiuva, 2125, Andar 2 Sala 2, 88015-530',
    description: 'Footer of the page: header of the address of the representative office in Brazil',
  },
  ch: {
    defaultMessage:
      'China, Guangdong, Shenzhen, Kerry Plaza Tower 3, Level 22, Suite 2202, No. 1-1 Zhong Xin Fourth Road, Futian District, 518048',
    description: 'Footer of the page: address of the group of companies',
  },
  de: {
    defaultMessage: 'Joom Pharm Solutions GmbH{br}Berlin, Potsdamer Platz 1, 10785',
    description: 'Footer of the page: address of the group of companies',
  },
  hk: {
    defaultMessage:
      'Joom Logistics Limited{br}Hong Kong, Kowloon, Tsim Sha Tsui East, 98 Granville Road, East Ocean Centre, Unit 18, 5/F',
    description: 'Footer of the page: address of the group of companies',
  },
  lv: {
    defaultMessage: 'SIA Joom{br}Latvija, Rīga, Gustava Zemgala gatve 78-1, LV1039',
    description: 'Footer of the page: address of the group of companies',
  },
  pt: {
    defaultMessage: 'JOOM, UNIPESSOAL LDA{br}Portugal, Lisboa, Av. Duque de Loulé 110, 1050-045',
    description: 'Footer of the page: address of the group of companies',
  },
  ru: {
    defaultMessage: ' "JOOM" LLC{br}INN 7714448295. Moscow, Aviakonstruktora Sukhoi str., 2, b. 1, 3-95',
    description: 'Footer of the page: address of the group of companies',
  },
  us: {
    defaultMessage: 'Joom USA Inc.{br}CA, San Diego, High Bluff Drive, Suite 200, 92130',
    description: 'Footer of the page: address of the group of companies',
  },
});

type Props = {
  hideOffices?: boolean;
  index: number;
  office: Office;
  withDivider?: boolean;
};

export function FooterOffice({office, index, hideOffices, withDivider}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div
      className={cn(
        styles.office,
        hideOffices && index >= MOBILE_OFFICES_NUMBER && styles.hidden,
        withDivider && styles.withDivider,
      )}
    >
      <img alt={office} className={styles.flag} src={FLAG_SRC[office]} />
      {intl.formatMessage(officeMessages[office], {br: <br />})}
    </div>
  );
}
