import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

export type FooterRepresentativeOfficeTestId = unknown;

const messages = defineMessages({
  address: {
    defaultMessage:
      'JoomPro Brazil HQ Brasil, São Paulo SP, Bela Vista, Av Paulista, 1374, Andar 5 Sala 103, 01310-100',
    description: 'Footer of the page: header of the address of the representative office in Brazil',
  },
  title: {
    defaultMessage: 'Representative office in Brazil:',
    description: 'Footer of the page: header of the address of the representative office in Brazil',
  },
});

type Props = TestIdProp<FooterRepresentativeOfficeTestId>;

export function FooterRepresentativeOffice({testId}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId}>
      <div className={styles.icon} />
      <div className={styles.title}>
        <FormattedMessage {...messages.title} />
      </div>
      <div className={styles.address}>
        <img alt='pt-br' className={styles.flag} src='/flags/pt-br.svg' />
        <FormattedMessage {...messages.address} values={{br: <br />}} />
      </div>
    </div>
  );
}
