import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {FbLogoLink} from './fb';
import styles from './index.module.scss';
import {InstagramLogoLink} from './instagram';
import {LinkedInLogoLink} from './linkedIn';
import {TgLogoLink} from './tg';
import {VcLogoLink} from './vc';

const messages = defineMessages({
  title: {
    defaultMessage: 'Subscribe to us',
    description: 'Footer of the page: content title',
  },
});

export function FooterSocial(): React.ReactElement {
  const language = useLanguage();
  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage {...messages.title} />
      </div>
      <div className={styles.socials}>
        {language === Language.PT_BR ? (
          <>
            <FbLogoLink />
            <LinkedInLogoLink />
            <InstagramLogoLink />
          </>
        ) : (
          <>
            <VcLogoLink />
            <TgLogoLink />
          </>
        )}
      </div>
    </div>
  );
}
