import {Link} from 'components/Link';
import {Logo} from 'components/Logo';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';

export type LogoLinkTestId = unknown;

export type Props = TestIdProp<LogoLinkTestId> & {
  gridArea?: string;
};

export function LogoLink({testId, gridArea}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleMainClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'logo',
        source: appEnv.currentPageName,
      },
      type: 'mainClick',
    });
  }, [analytics, appEnv]);

  return (
    <Link data-test-id={testId} href={reverseUrl.main()} onClick={handleMainClick} style={{gridArea}}>
      <Logo />
    </Link>
  );
}
