import cn from 'classnames';
import {Link} from 'components/Link';
import React from 'react';
import styles from './index.module.scss';

export function LinkedInLogoLink(): React.ReactElement {
  return (
    <Link href='https://www.linkedin.com/company/joomprobrasil'>
      <div className={cn(styles.logo, styles.linkedIn)} />
    </Link>
  );
}
