import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import React from 'react';
import styles from './index.module.scss';
import RuLogoIcon from './logo-ru.svg';
import LogoIcon from './logo.svg';

export function Logo(): React.ReactElement {
  const language = useLanguage();

  if (language === Language.RU) {
    return <RuLogoIcon className={styles.icon} />;
  }

  return <LogoIcon className={styles.icon} />;
}
