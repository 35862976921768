import {Link, LinkTestId} from 'components/Link';
import {MarketingEmail, MarketingEmailTestId} from 'components/MarketingEmail';
import {SupportEmail, SupportEmailTestId} from 'components/SupportEmail';
import {isPhoneShownFor, SupportPhone, SupportPhoneTestId} from 'components/SupportPhone';
import {config} from 'config';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {getInterfaceLanguages} from 'lib/language';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {reverseUrl} from 'lib/router';
import {MERCHANT_JOOM_PRO_URL, JOOM_PRO_URL} from 'lib/router/constants';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useMemo} from 'react';
import {defineMessages} from 'react-intl';
import {FooterLanguageSwitch, FooterLanguageSwitchTestId} from '../FooterLanguageSwitch';
import styles from './index.module.scss';

const messages = defineMessages({
  about: {
    defaultMessage: 'About our company',
    description: 'Footer of the page: link to the section',
  },
  blog: {
    defaultMessage: 'Blog',
    description: 'Footer of the page: link to the section',
  },
  privacy: {
    defaultMessage: 'Privacy policy',
    description: 'Footer of the page: link to the section',
  },
  seller: {
    defaultMessage: 'Become a seller',
    description: 'Footer of the page: link to the section',
  },
  support: {
    defaultMessage: 'Customer Support',
    description: 'Footer of the page: link to the section',
  },
  terms: {
    defaultMessage: 'Terms of Service',
    description: 'Footer of the page: link to the section',
  },
});

export type FooterNavigationTestId = {
  blog: LinkTestId;
  languageSwitch: FooterLanguageSwitchTestId;
  marketingEmail: MarketingEmailTestId;
  privacy: LinkTestId;
  seller: LinkTestId;
  support: LinkTestId;
  supportEmail: SupportEmailTestId;
  supportPhone: SupportPhoneTestId;
  terms: LinkTestId;
};

type Props = TestIdProp<FooterNavigationTestId> & {
  children?: React.ReactNode;
  isAnalyticsPage?: boolean;
};

export function FooterNavigation({testId, isAnalyticsPage, children}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const language = useLanguage();
  const languages = useMemo(() => {
    return getInterfaceLanguages(language);
  }, [language]);
  const analytics = useAnalytics();

  const handlePolicyClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'footer',
        source: appEnv.currentPageName,
      },
      type: 'policyClick',
    });
  }, [analytics, appEnv]);

  const handleTermsClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'footer',
        source: appEnv.currentPageName,
      },
      type: 'termsClick',
    });
  }, [analytics, appEnv]);

  const handleMerchantClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        link: MERCHANT_JOOM_PRO_URL.root,
        pageUrl: window.location.href,
        position: 'footer',
        source: appEnv.currentPageName,
      },
      type: 'externalLinkClick',
    });
  }, [analytics, appEnv]);

  return (
    <div className={styles.container} data-test-id={testId}>
      {language === Language.PT_BR && (
        <Link
          className={styles.link}
          // language here to avoid hydrate warning
          // because on analytics.joom.pro we don't have language prefix on client side but have on server side
          href={
            isAnalyticsPage ? `${JOOM_PRO_URL.root}/${language}${reverseUrl.blog()}` : `${language}${reverseUrl.blog()}`
          }
          message={messages.blog}
          testId={testId?.blog}
        />
      )}
      <Link
        className={styles.link}
        // language here to avoid hydrate warning
        // because on analytics.joom.pro we don't have language prefix on client side but have on server side
        href={`/${language}${reverseUrl.policy()}`}
        message={messages.privacy}
        onClick={handlePolicyClick}
        target='_blank'
        testId={testId?.privacy}
      />
      {isAnalyticsPage && (
        <Link
          className={styles.link}
          // language here to avoid hydrate warning
          // because on analytics.joom.pro we don't have language prefix on client side but have on server side
          href={`/${language}${reverseUrl.terms()}`}
          message={messages.terms}
          onClick={handleTermsClick}
          target='_blank'
          testId={testId?.terms}
        />
      )}
      <Link
        className={styles.link}
        href={MERCHANT_JOOM_PRO_URL.root}
        message={messages.seller}
        onClick={handleMerchantClick}
        target='_blank'
        testId={testId?.seller}
      />
      {isPhoneShownFor(language) && (
        <span className={styles.contact}>
          <SupportPhone format='phone' testId={testId?.supportPhone} />
        </span>
      )}
      {isAnalyticsPage ? (
        <SupportEmail className={styles.contact} email={config.supportAnalyticsEmail} testId={testId?.supportEmail} />
      ) : (
        <SupportEmail className={styles.contact} testId={testId?.supportEmail} />
      )}
      {language === Language.PT_BR ? (
        <MarketingEmail className={styles.contact} testId={testId?.marketingEmail} />
      ) : undefined}
      {!!languages.length && (
        <div className={styles.language}>
          <FooterLanguageSwitch languages={languages} testId={testId?.languageSwitch} />
        </div>
      )}
      {children}
    </div>
  );
}
