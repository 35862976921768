import {config} from 'config';
import {useBaseUrl} from 'lib/baseUrl';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {getLanguageRegion} from 'lib/language';
import {OpenGraph as OpenGraphType} from 'lib/openGraph/types';
import {openGraphItemsMap} from 'lib/openGraph/utils';
import {TwitterMetaTags, twitterMetaTagsMap} from 'lib/twitter';
import {buildCanonicalUrl} from 'lib/url';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

type Props = {
  children?: React.ReactNode;
  description?: ExtMessageDescriptor;
  openGraph?: OpenGraphType;
  title?: ExtMessageDescriptor;
  twitter?: TwitterMetaTags;
};

function getLinkHref(baseUrl: URL, path: string, locale: string): string {
  let url = baseUrl;

  if (config.releaseStage === 'production') {
    if (locale === 'ru' || locale === 'en') {
      url = new URL('https://joompro.ru');
    } else {
      url = new URL('https://joom.pro');
    }
  }

  return buildCanonicalUrl(url, path, locale);
}

export function Metadata({title, children, openGraph = {}, twitter = {}, description}: Props): React.ReactElement {
  const intl = useIntl();
  const baseUrl = useBaseUrl();
  const router = useRouter();
  const canonicalUrl = buildCanonicalUrl(baseUrl, router.asPath, router.locale, router.defaultLocale);
  const titleString = buildMessage(intl, title);
  const descriptionString = buildMessage(intl, description);

  const openGraphMetaTags = useMemo(() => {
    const openGraphData: OpenGraphType = {
      description: descriptionString,
      locale: getLanguageRegion(router.locale),
      siteName: 'JoomPro',
      title: titleString,
      type: 'website',
      url: canonicalUrl,
    };

    const openGraphExtended = {...openGraphData, ...openGraph};

    return openGraphItemsMap(openGraphExtended, (key, value) => {
      const property = `og:${key}`;
      return <meta content={value} key={property} property={property} />;
    });
  }, [openGraph, canonicalUrl, titleString, descriptionString]);

  const twitterMetaTags = useMemo(() => {
    const twitterData: TwitterMetaTags = {
      description: descriptionString,
      title: titleString,
    };

    const twitterDataExtended = {...twitterData, ...twitter};

    return twitterMetaTagsMap(twitterDataExtended, (key, value) => {
      const property = `twitter:${key}`;
      return <meta content={value} key={property} name={property} />;
    });
  }, [twitter, titleString, descriptionString]);

  return (
    <Head>
      {title && <title>{titleString}</title>}
      {description && <meta content={descriptionString} name='description' />}
      <meta content='telephone=no' name='format-detection' />
      <link href={canonicalUrl} rel='canonical' />
      {router.locales?.map((locale) => (
        <link href={getLinkHref(baseUrl, router.asPath, locale)} hrefLang={locale} key={locale} rel='alternate' />
      ))}
      {children}
      {openGraphMetaTags}
      {twitterMetaTags}
    </Head>
  );
}
