import {Link, LinkProps} from 'components/Link';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

type AnchorClickHandler = React.MouseEventHandler<HTMLAnchorElement>;
type ButtonClickHandler = React.MouseEventHandler<HTMLButtonElement>;
type Props = TestIdProp & {
  children: React.ReactNode;
  href?: string;
  locale?: LinkProps['locale'];
  nativeLink?: boolean;
  onClick?: ButtonClickHandler | AnchorClickHandler;
};

export function MenuItem({children, href, locale, nativeLink, onClick, testId}: Props): React.ReactElement {
  if (href) {
    const Tag = nativeLink ? 'a' : Link;

    return (
      <Tag className={styles.menuItem} data-test-id={testId} href={href} onClick={onClick as AnchorClickHandler}>
        {children}
      </Tag>
    );
  }

  return (
    <button className={styles.menuItem} data-test-id={testId} onClick={onClick as ButtonClickHandler} type='button'>
      {children}
    </button>
  );
}
