import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Office} from '../types';
import {MOBILE_OFFICES_NUMBER} from './constants';
import styles from './index.module.scss';
import {FooterOffice} from './Office';

const messages = defineMessages({
  companyOffices: {
    defaultMessage: 'Group company offices',
    description: 'Footer of the page: header of the offices section',
  },
  hideOfficesButton: {
    defaultMessage: 'Hide other offices',
    description: 'Page footer: offices section: hide button',
  },
  showOfficesButton: {
    defaultMessage: 'Show other offices',
    description: 'Page footer: offices section: display button',
  },
});

/* eslint-enable max-len */

export type FooterOfficesTestId = {
  hideButton: unknown;
};

type Props = TestIdProp<FooterOfficesTestId> & {
  offices: Office[];
};

export function FooterOffices({testId, offices}: Props): React.ReactElement {
  const intl = useIntl();

  const {toggle: toggleHidden, value: hideOffices} = useBooleanState(true);

  return (
    <div className={styles.offices} data-test-id={testId}>
      <div className={styles.title}>{intl.formatMessage(messages.companyOffices)}</div>
      <div className={styles.list}>
        {offices.map((office, i) => (
          <FooterOffice hideOffices={hideOffices} index={i} key={i} office={office} />
        ))}
      </div>
      {offices.length > MOBILE_OFFICES_NUMBER && (
        <button className={styles.hideButton} data-test-id={testId?.hideButton} onClick={toggleHidden} type='button'>
          {intl.formatMessage(hideOffices ? messages.showOfficesButton : messages.hideOfficesButton)}
        </button>
      )}
    </div>
  );
}
