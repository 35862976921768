import {createPopper, BasePlacement, VariationPlacement} from '@popperjs/core';
import cn from 'classnames';
import {useIsomorphicLayoutEffect} from 'lib/hooks';
import React, {useRef} from 'react';
import {ModalOverlay} from 'uikit/ModalOverlay';
import styles from './index.module.scss';

export type PopoverPlacement = BasePlacement | VariationPlacement;

export type PopoverProps = {
  children: React.ReactNode;
  hideArrow?: boolean;
  isRounded?: boolean;
  onClose?: () => void;
  placement?: PopoverPlacement;
  targetRef?: React.RefObject<Element>;
  transparent?: boolean;
};

export function Popover({
  children,
  onClose,
  targetRef,
  hideArrow = false,
  isRounded = false,
  placement = 'bottom',
  transparent = false,
}: PopoverProps): React.ReactElement {
  const anchorRef = useRef(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    const referenceElement = targetRef ? targetRef.current : anchorRef.current;

    if (!referenceElement || !popoverRef.current) {
      return undefined;
    }

    const distance = hideArrow ? 8 : 10;
    const popper = createPopper(referenceElement, popoverRef.current, {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 16,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, distance],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowRef.current,
            padding: 16,
          },
        },
      ],
      placement,
    });

    return () => {
      popper.destroy();
    };
  }, [targetRef, placement, hideArrow]);

  const content = (
    <ModalOverlay onClose={onClose} transparent>
      <div
        className={cn(styles.popover, {[styles.withBackground]: !transparent, [styles.rounded]: isRounded})}
        ref={popoverRef}
      >
        {!hideArrow && <div className={styles.arrow} ref={arrowRef} />}
        <div className={styles.content}>{children}</div>
      </div>
    </ModalOverlay>
  );

  if (targetRef) {
    return content;
  }

  return <div ref={anchorRef}>{content}</div>;
}
