import {Image, ImageTestId} from 'components/Image';
import {Link, LinkTestId} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {JOOM_URL, JOOM_LOGISTICS_URL} from 'lib/router/constants';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

export type FooterCompaniesTestId = {
  image: ImageTestId;
  link: LinkTestId;
};

type Props = TestIdProp<FooterCompaniesTestId>;

type Company = {
  href: string;
  src: string;
};

const companies: Company[] = [
  {
    href: JOOM_URL.root,
    src: '/illustrations/logos/joom.svg',
  },
  {
    href: JOOM_LOGISTICS_URL.root,
    src: '/illustrations/logos/joomLogisticsInverse.svg',
  },
];

export function FooterCompanies({testId}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      analytics.sendEvent({
        payload: {
          link: event.currentTarget.href,
          pageUrl: window.location.href,
          position: 'footer',
          source: appEnv.currentPageName,
        },
        type: 'externalLinkClick',
      });
    },
    [analytics, appEnv],
  );

  return (
    <div className={styles.companies} data-test-id={testId}>
      <div className={styles.title}>
        <FormattedMessage
          defaultMessage='Joom Group companies'
          description='Footer of the page: header of the companies section'
        />
      </div>
      <div className={styles.list}>
        {companies.map((company) => (
          <Link href={company.href} key={company.href} onClick={handleLinkClick} target='_blank' testId={testId?.link}>
            <Image loading='lazy' src={company.src} testId={testId?.image} />
          </Link>
        ))}
      </div>
    </div>
  );
}
