import {Office} from 'components/Footer/types';

export const MOBILE_OFFICES_NUMBER = 3;
export const FLAG_SRC: Record<Office, string> = {
  br: '/flags/pt-br.svg',
  ch: '/flags/cn.svg',
  de: '/flags/de.svg',
  hk: '/flags/hk.svg',
  lv: '/flags/lv.svg',
  pt: '/flags/pt.svg',
  ru: '/flags/ru.svg',
  us: '/flags/us.svg',
};
