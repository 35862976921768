import cn from 'classnames';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import React from 'react';
import {defineMessages, FormattedMessage, MessageDescriptor} from 'react-intl';
import styles from './index.module.scss';

type MessagesItem = {
  address: MessageDescriptor;
  name: MessageDescriptor;
};

const ruOffice: MessagesItem = defineMessages({
  address: {
    defaultMessage: 'Armenia, Yerevan, Malatia-Sebastia, Sebastia St., 37/5, INN 01328198',
    description: 'Footer. Legal address faces (for the Russian version of the site)',
  },
  name: {
    defaultMessage: '"Internet Projects" LLC',
    description: 'Footer. Legal name faces (for the Russian version of the site)',
  },
});

const enOffice: MessagesItem = defineMessages({
  address: {
    defaultMessage: 'Latvija, Rīga, Gustava Zemgala gatve 78-1, LV1039',
    description: 'Footer. Legal address faces',
  },
  name: {
    defaultMessage: 'SIA Joom',
    description: 'Footer. Legal name faces',
  },
});

const messagesByLanguage: Record<Language, MessagesItem> = {
  [Language.PT_BR]: enOffice,
  [Language.EN]: ruOffice,
  [Language.RU]: ruOffice,
};

type Props = {
  address?: MessageDescriptor;
  name?: MessageDescriptor;
};

export function FooterAddress(props: Props): React.ReactElement {
  const language = useLanguage();
  const {name, address} = messagesByLanguage[language];

  return (
    <div>
      <div className={cn(styles.mainAddressPart, styles.mainAddressName)}>
        <div className={styles.icon} />
        <FormattedMessage {...(props.name || name)} />
      </div>
      <div className={styles.mainAddressPart}>
        <FormattedMessage {...(props.address || address)} />
      </div>
    </div>
  );
}
