import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Popover, PopoverProps, PopoverPlacement} from 'uikit/Popover';
import styles from './index.module.scss';

export {MenuItem} from './MenuItem';

export type MenuTestId = unknown;

export type MenuProps = Pick<PopoverProps, 'targetRef'> &
  TestIdProp<MenuTestId> & {
    children: React.ReactNode;
    hideArrow?: boolean;
    isRounded?: boolean;
    onClose?: () => void;
    placement?: PopoverPlacement;
  };

export function Menu({
  children,
  placement,
  hideArrow,
  onClose,
  targetRef,
  testId,
  isRounded,
}: MenuProps): React.ReactElement {
  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <Popover hideArrow={hideArrow} isRounded={isRounded} onClose={onClose} placement={placement} targetRef={targetRef}>
      <div className={styles.menu} data-test-id={testId} onClick={onClose}>
        {children}
      </div>
    </Popover>
  );
}
