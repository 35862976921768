import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import cn from 'classnames';
import {LanguageMenu, LanguageMenuTestId} from 'components/LanguageMenu';
import {getLanguageLongName} from 'lib/language';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type FooterLanguageSwitchTestId = {
  button: unknown;
  menu: LanguageMenuTestId;
};

type Props = TestIdProp<FooterLanguageSwitchTestId> & {
  languages: Language[];
};

export function FooterLanguageSwitch({testId, languages}: Props): React.ReactElement {
  const language = useLanguage();
  const {open, close, isOpen} = usePopupState(false);
  const languageName = getLanguageLongName(language);

  return (
    <div className={styles.container} data-test-id={testId}>
      <button className={styles.button} data-test-id={testId?.button} onClick={open} type='button'>
        <div className={cn(styles.icon, styles.globe)} />
        {languageName}
        <div className={cn(styles.icon, styles.arrow)} />
      </button>
      {isOpen && (
        <LanguageMenu hideArrow languages={languages} onClose={close} placement='bottom-end' testId={testId?.menu} />
      )}
    </div>
  );
}
