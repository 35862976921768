import {config} from 'config';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {useMemo} from 'react';
import {AppConfig} from './types';

type LocaleDependentConfigProps = 'locales' | 'marketingEmail' | 'phone' | 'supportEmail' | 'supportPhone';

type AppLocaleConfig = Omit<AppConfig, LocaleDependentConfigProps> & {
  [k in LocaleDependentConfigProps]: AppConfig[k][Language];
};

export const useLocaleConfig = (): AppLocaleConfig => {
  const language = useLanguage();

  return useMemo(
    () => ({
      ...config,
      locales: config.locales[language],
      marketingEmail: config.marketingEmail[language],
      phone: config.phone[language],
      supportEmail: config.supportEmail[language],
      supportPhone: config.supportPhone[language],
    }),
    [language],
  );
};
