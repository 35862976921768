import {LogoLink} from 'components/LogoLink';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useElementPreview} from 'lib/hooks';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useMemo, useRef} from 'react';
import {ChinaFooterAddress} from './ChinaFooterAddress';
import {FooterAddress} from './FooterAddress';
import {FooterBottom} from './FooterBottom';
import {FooterCompanies, FooterCompaniesTestId} from './FooterCompanies';
import {FooterConnection} from './FooterConnection';
import {FooterNavigation, FooterNavigationTestId} from './FooterNavigation';
import {FooterOffices, FooterOfficesTestId} from './FooterOffices';
import {FooterOffice} from './FooterOffices/Office';
import {FooterRepresentativeOffice} from './FooterRepresentativeOffice';
import {FooterSocial} from './FooterSocial';
import styles from './index.module.scss';
import {Office} from './types';

const officesByLanguage: Record<Language, Office[] | undefined> = {
  [Language.RU]: [],
  [Language.EN]: [],
  [Language.PT_BR]: ['br', 'pt', 'hk'],
};

export type FooterTestId = {
  companies: FooterCompaniesTestId;
  navigation: FooterNavigationTestId;
  offices: FooterOfficesTestId;
};

type Props = TestIdProp<FooterTestId> & {
  isAnalyticsPage?: boolean;
  onOpenConsultationDialog?(sectionId: string): void;
};

export function Footer({testId, onOpenConsultationDialog, isAnalyticsPage}: Props): React.ReactElement {
  const language = useLanguage();
  const footerContainerRef = useRef(null);
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  useElementPreview(
    footerContainerRef,
    () => {
      analytics.dataLayer({
        element: 'footer',
        event: 'elementPreview',
        source: appEnv.currentPageName,
      });
    },
    {threshold: 0.25},
  );

  const handleOpenConsultationDialog = useCallback(() => {
    onOpenConsultationDialog?.('footer');
  }, [onOpenConsultationDialog]);

  const offices = useMemo(() => officesByLanguage[language] ?? [], [language]);
  const showOffices = !!offices.length;
  const showChinaOfficeOnTop = language === Language.PT_BR;
  const showCompanies = language !== Language.RU && language !== Language.EN;
  const connectionInNavigation = language === Language.PT_BR;
  const showRepresentativeOffice = language === Language.PT_BR;
  const showSocial = language === Language.RU || language === Language.EN || language === Language.PT_BR;

  return (
    <footer className={styles.footer} data-test-id={testId} ref={footerContainerRef}>
      <div className={styles.logo}>
        <LogoLink />
      </div>
      <div className={styles.columns}>
        <FooterNavigation isAnalyticsPage={isAnalyticsPage} testId={testId?.navigation}>
          {onOpenConsultationDialog && connectionInNavigation && (
            <FooterConnection onOpenConsultationDialog={handleOpenConsultationDialog} strongTitle />
          )}
        </FooterNavigation>
        <div className={showChinaOfficeOnTop ? styles.doubleAddress : undefined}>
          <FooterAddress />
          {showChinaOfficeOnTop && (
            <div className={showChinaOfficeOnTop ? styles.chinaAddress : undefined}>
              <ChinaFooterAddress /> <FooterOffice index={0} office={'ch'} />
            </div>
          )}
        </div>
        {onOpenConsultationDialog && !connectionInNavigation && (
          <FooterConnection onOpenConsultationDialog={handleOpenConsultationDialog} />
        )}
        {showRepresentativeOffice && <FooterRepresentativeOffice />}
        {showSocial && <FooterSocial />}
      </div>
      {showOffices && <FooterOffices offices={offices} testId={testId?.offices} />}
      {showCompanies && <FooterCompanies testId={testId?.companies} />}
      <FooterBottom />
    </footer>
  );
}
