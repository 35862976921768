import {isRecord} from 'lib/guards';
import snakeCase from 'lodash/snakeCase';
import {OpenGraph} from './types';

/**
 * Open graph's metatags have format like og:[some_key]:[one_more_key]
 * Symbol ":" separate each key in meta tag.
 */
const OG_KEYS_SEPARATOR = ':';

const KEY_SNAKE_CASE_CACHE: Record<string, string> = {};

/**
 * Convert key to snake_case format. Have internal cache.
 * @example
 * 'secureUrl' -> 'secure_url'
 * 'url'       -> 'url'
 */
export function keySnakeCase(key: string): string {
  if (!(key in KEY_SNAKE_CASE_CACHE)) {
    KEY_SNAKE_CASE_CACHE[key] = snakeCase(key);
  }

  return KEY_SNAKE_CASE_CACHE[key];
}

/**
 * Concatenate key parts
 */
function makeCompositeKey(compositeKey: string, key: string): string {
  return compositeKey ? `${compositeKey}${OG_KEYS_SEPARATOR}${keySnakeCase(key)}` : keySnakeCase(key);
}

type OpenGraphTree = {
  [key: string]: OpenGraphTree | string | number | undefined;
};
type ForEachCallback = (key: string, value: string) => void;

/**
 * Iterates over items of OpenGraph tree and invokes callback for each item.
 * The callback is invoked with two arguments: key and value
 */
export function openGraphItemsForEach(graph: OpenGraph, cb: ForEachCallback): void {
  function itemsForEach(subTree: OpenGraphTree, compositeKey: string, parentKey: string, level: number) {
    Object.keys(subTree).forEach((key) => {
      const value = subTree[key];
      if (isRecord(value)) {
        itemsForEach(value, makeCompositeKey(compositeKey, key), key, level + 1);
      } else if (value !== undefined) {
        let itemCompositeKey: string;
        if ((parentKey === 'image' || parentKey === 'video' || parentKey === 'audio') && key === 'url' && level === 1) {
          itemCompositeKey = compositeKey;
        } else {
          itemCompositeKey = makeCompositeKey(compositeKey, key);
        }

        cb(itemCompositeKey, String(value));
      }
    });
  }

  itemsForEach(graph, '', '', 0);
}

type MapCallback<Result> = (key: string, value: string) => Result;

/**
 * Creates an array of values by running each item in OpenGraph tree thru callback.
 * The callback is invoked with two arguments: key and value
 */
export function openGraphItemsMap<Result>(graph: OpenGraph, cb: MapCallback<Result>): Result[] {
  const result: Result[] = [];

  openGraphItemsForEach(graph, (key, value) => {
    result.push(cb(key, value));
  });

  return result;
}
