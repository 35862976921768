import cn from 'classnames';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Contact a manager',
    description: 'Footer of the page: pop-up window call button',
  },
  title: {
    defaultMessage: 'Do you still have questions?',
    description: 'Footer of the page: content title',
  },
});

type Props = {
  onOpenConsultationDialog?(): void;
  strongTitle?: boolean;
};

export function FooterConnection({onOpenConsultationDialog, strongTitle}: Props): React.ReactElement {
  return (
    <div>
      <div className={cn(styles.title, strongTitle && styles.strongTitle)}>
        <FormattedMessage {...messages.title} />
      </div>
      <button className={styles.button} onClick={onOpenConsultationDialog} type='button'>
        <FormattedMessage {...messages.button} />
      </button>
    </div>
  );
}
