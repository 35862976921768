export type TwitterMetaTags = {
  card?: string;
  description?: string;
  image?: string;
  title?: string;
};

type MapCallback<Result> = (key: string, value: string) => Result;

export function twitterMetaTagsMap<Result>(tags: TwitterMetaTags, cb: MapCallback<Result>): Result[] {
  const result: Result[] = [];

  Object.keys(tags).forEach((key) => {
    const value = tags[key as keyof TwitterMetaTags];
    if (value !== undefined) {
      result.push(cb(key, value));
    }
  });

  return result;
}
