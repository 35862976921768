import {config} from 'config';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';
import PhoneIcon from './phone.svg';

export type SupportPhoneTestId = {
  phone: unknown;
};

type Props = TestIdProp<SupportPhoneTestId> & {
  format?: 'phone' | 'icon' | 'full';
  iconWidth?: number;
};

const phoneShownForLanguages = [Language.RU, Language.EN];
export const isPhoneShownFor = (language: Language): boolean => phoneShownForLanguages.includes(language);

export function SupportPhone({testId, iconWidth, format = 'full'}: Props): React.ReactElement {
  const language = useLanguage();

  const iconVisible = format !== 'phone';
  const phoneVisible = format !== 'icon';

  const phone = config.supportPhone[language];

  return (
    <div data-test-id={testId}>
      <a
        className={styles.link}
        href={`tel:${phone}`}
        target='_parent' // without target "_parent" href:tel won't work in iframe on iOS
      >
        {iconVisible && <PhoneIcon style={{fontSize: iconWidth}} />}
        {phoneVisible && (
          <span className={styles.phone} data-test-id={testId?.phone}>
            {phone}
          </span>
        )}
      </a>
    </div>
  );
}
