import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {FooterAddress} from '../FooterAddress';

type MessagesItem = {
  address: MessageDescriptor;
  name: MessageDescriptor;
};

const chinaOffice: MessagesItem = defineMessages({
  address: {
    defaultMessage: 'Trade Company Limited:',
    description: 'Footer. Legal address faces',
  },
  name: {
    defaultMessage: 'Shenzhen JoomPro International',
    description: 'Footer. Legal name faces',
  },
});

type Props = {
  address?: MessageDescriptor;
  name?: MessageDescriptor;
};

export function ChinaFooterAddress(props: Props): React.ReactElement {
  return <FooterAddress {...chinaOffice} />;
}
