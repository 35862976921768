import {useCallbackWithoutArgs} from 'lib/hooks';
import {getLanguageLongName} from 'lib/language';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import {useRouter} from 'next/router';
import React, {useMemo} from 'react';
import {Menu, MenuItem, MenuProps} from 'uikit/Menu';
import styles from './index.module.scss';

export type LanguageMenuTestId = {
  item: {
    [key in Language]: unknown;
  };
};

export type Props = Pick<MenuProps, 'targetRef' | 'hideArrow' | 'placement'> &
  TestIdProp<LanguageMenuTestId> & {
    languages: Language[];
    onClose?: () => void;
  };

export function LanguageMenu({onClose, languages, placement, targetRef, hideArrow, testId}: Props): React.ReactElement {
  const router = useRouter();
  const currentLanguage = useLanguage();
  const path = router.asPath === '/' ? '' : router.asPath;
  const handleClose = useCallbackWithoutArgs(onClose);

  const languageItems = useMemo(() => {
    return languages
      .map((code) => ({code, name: getLanguageLongName(code)}))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [languages]);

  return (
    <Menu hideArrow={hideArrow} onClose={onClose} placement={placement} targetRef={targetRef} testId={testId}>
      {languageItems.map((language) =>
        language.code !== currentLanguage ? (
          <MenuItem
            href={`/${language.code}${path}`}
            key={language.code}
            nativeLink
            onClick={handleClose}
            testId={testId?.item[language.code]}
          >
            <span className={styles.text}>{language.name}</span>
          </MenuItem>
        ) : null,
      )}
    </Menu>
  );
}
