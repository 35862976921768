import cn from 'classnames';
import {Link} from 'components/Link';
import React from 'react';
import styles from './index.module.scss';

export function TgLogoLink(): React.ReactElement {
  return (
    <Link href='https://t.me/projoom'>
      <div className={cn(styles.logo, styles.telegram)} />
    </Link>
  );
}
