import {useLocaleConfig} from 'lib/appConfig/hooks';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';

export type MarketingEmailTestId = unknown;

type Props = TestIdProp<MarketingEmailTestId> & {
  children?: React.ReactNode;
  className?: string;
  email?: string;
};

export function MarketingEmail({children, email: emailFromProps, className, testId}: Props): React.ReactElement {
  const {marketingEmail} = useLocaleConfig();
  const email = emailFromProps || marketingEmail;

  if (!email) {
    return <></>;
  }

  return (
    <a className={className} data-test-id={testId} href={`mailto:${email}`} rel='noopener noreferrer' target='_blank'>
      {children || email}
    </a>
  );
}
